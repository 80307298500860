import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { services } from "@/utils/services";


createApp(App).use(store).use(router).mount('#app');




