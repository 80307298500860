import { services } from "@/utils/services";
import { getFunctions, httpsCallable } from "firebase/functions";

export async function getOnBoardingLink(email: string) {
  const { fb } = services;
  const functions = getFunctions(fb, "australia-southeast1");
  const getOnBoardingLink = httpsCallable(functions, "stripe-expressConnectOnboard");
  return getOnBoardingLink({ email })
    .then((result: any) => {
     
      // Read result of the Cloud Function.
      /** @type {any} */
      return result.data.url;
    })
    .catch(() => {
      return { error: "An unexpected error has occurred" };
    });
}
