import { services } from "@/utils/services";

import { getFunctions, httpsCallable } from "firebase/functions";

export async function getLoginLink(id: string) {
  const { fb } = services;
  const functions = getFunctions(fb, "australia-southeast1");
  const getLoginLink = httpsCallable(functions, "stripe-getExpressDashboardLoginLink");
  return getLoginLink({ id })
    .then((result: any) => {
      debugger;
      console.log(result);

      return result.data;
    })
    .catch((err) => {
      return { error: "An unexpected error has occurred" };
    });
}
