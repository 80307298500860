
import { defineComponent, ref, computed, onMounted } from "vue";
import { getOnBoardingLink } from "@/services/stripe/get-onboarding-link";
export default defineComponent({
  name: "Home",
  setup() {
    const disabled = ref(false);
    const email = ref("");

    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const validEmailAddress = computed(() => emailRegex.test(email.value));

    const createAccount = async () => {
      disabled.value = true;
      if (!validEmailAddress.value) {
        alert("Please enter a valid email address");
        disabled.value = false;
      } else {
        try {
          const accountLink = await getOnBoardingLink(email.value);
          disabled.value = false;

          window.location = accountLink;
        } catch {
          disabled.value = false;
          alert("an error has occurred, please try again later");
        }
      }
    };
    return {
      createAccount,
      disabled,
      email,
    };
  },
});
