import { apiBaseUrl } from "@/utils/base-url";
import { post } from "@/utils/http";
export async function getRefreshToken(
    token: string,
    authCode: string,
    redirectUri: string
): Promise<any> {
    const url = `${apiBaseUrl}/lightspeed/token`;
    return post(url, { authCode, redirectUri }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}


export async function authoriseSites(
    token:string,request: {sites:any,encryptedRefreshToken:any}
):Promise<any> {
    const url = `${apiBaseUrl}/sites/create-sites`;
    return post(url,request,{
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}