import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"
import Result from "../views/Result.vue"
import CreateSites from "../views/CreateSites.vue"
import LightspeedAuth from "../views/LightspeedAuth.vue"
import { getAuth } from "firebase/auth";
import { services } from "@/utils/services";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/lightspeed-auth",
    name: "LightspeedAuth",
    component: LightspeedAuth
  },
  {
    path: "/create-sites",
    name: "CreateSite",
    component: CreateSites
  },
  {
    path: "/result",
    name: "Result",
    component: Result
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
