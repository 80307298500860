
import { defineComponent, ref, computed, onMounted } from "vue";

export default defineComponent({
  name: "Home",
  setup() {
    const disabled = ref(false);
    const randomAlphanumeric = (length: number) => {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    const state = () => {
      const state = randomAlphanumeric(8);
      localStorage.setItem("lightspeed-oauth-state", state);
      return state;
    };

    const auth = () => {
      disabled.value = true;
      window.location.href = `https://my.kounta.com/authorize?client_id=${
        process.env.VUE_APP_LIGHTSPEED_CLIENT_ID
      }&redirect_uri=${
        process.env.VUE_APP_LIGHTSPEED_REDIRECT_URI
      }&state=${state()}&response_type=code`;
    };

    return {
      disabled,
      auth,
    };
  },
});
