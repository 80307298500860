
import { defineComponent, ref, onMounted } from "vue";
import { getLoginLink } from "@/services/stripe/get-login-link";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "Result",
  setup() {
    const buttonUrl = ref("");
    const title = ref("");
    const body = ref("");
    const buttonText = ref("");
    onMounted(async () => {
      const route = useRoute();
      const router = useRouter();
      if (route.query.id != undefined) {
        const loginLink = await getLoginLink(route.query.id as string);
        if (loginLink.error == undefined) {
          if (loginLink.object == "account_link") {
            buttonUrl.value = loginLink.url;
            title.value = "Application pending";
            body.value =
              "Please provide more details to complete the application";
            buttonText.value = "Complete Application";
          } else {
            buttonUrl.value = loginLink.url;
            title.value = "Account created";
            body.value = "You can login to your connect dashboard now.";
            buttonText.value = "Login";
          }
        } else {
          title.value = "Oops!";
          body.value =
            "Something wrong with the application, please try again.";
          buttonText.value = "Try again";
          buttonUrl.value = "/";
        }
      } else {
        router.replace("/");
      }
    });

    return {
      buttonUrl,
      title,
      body,
      buttonText
    };
  }
});
