
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { login } from "@/services/auth/login";
import { getRefreshToken, authoriseSites } from "@/services/lightspeed/service";
export default defineComponent({
  name: "CreateSites",
  setup() {
    const disabled = ref(false);
    const route = useRoute();
    const router = useRouter();
    const sites = ref();
    const encryptedRefreshToken = ref();
    const authorisedSites = ref([]);
    const title = ref();
    const content = ref("");
    const token = ref();
    const loading = ref("Loading sites...");
    const success = ref("Choose sites to onboard");
    const error = ref("Error loading sites");
    const authorisationError = ref("Error authorising sites");
    const complete = ref("We’re setting up your sites with Payo Checkout.");
    const completeContent = ref(
      "There are a few more steps to complete before you’re ready to start taking payments.<br>We'll contact you soon with more information.<br>You can close this window now."
    );

    onMounted(async () => {
      const user = await login();
      const code = route.query.code as string;
      const state = route.query.state as string;
      if (state != localStorage.getItem("lightspeed-oauth-state")) {
        title.value = error.value;
        return;
      }
      token.value = user.token;
      title.value = loading.value;
      if (code === undefined) {
        title.value = error.value;
      } else {
        try {
          const response = await getRefreshToken(
            token.value,
            code,
            process.env.VUE_APP_LIGHTSPEED_REDIRECT_URI
          );
          if ("sites" in response) {
            sites.value = response.sites;
            encryptedRefreshToken.value = response.encryptedRefreshToken;
            title.value = success.value;
          } else {
            title.value = error.value;
          }
        } catch (e) {
          title.value = error.value;
        }
      }
    });

    const request = () => {
      return {
        sites: sites.value
          .filter((site: any) =>
            authorisedSites.value.includes(site.id as never)
          )
          .map((site: any) => ({
            name: site.name,
            id: {
              name: site.name,
              company: site.companyId.toString(),
              site: site.id.toString(),
              provider: "lightspeed",
            },
            status: "onboarding",
          })),
        encryptedRefreshToken: encryptedRefreshToken.value,
      };
    };

    const authorise = async () => {
      disabled.value = true;
      if (authorisedSites.value.length == 0) {
        alert("Please choose at least 1 site");
        disabled.value = false;
        return;
      }
      const body = request();

      try {
        const response = await authoriseSites(token.value, body);
        title.value = complete.value;
        content.value = completeContent.value;
      } catch (e) {
        title.value = authorisationError.value;
      }
      disabled.value = false;
    };

    const retry = async () => {
      await router.push("/lightspeed-auth");
    };
    return {
      authorise,
      disabled,
      activeSites: computed(() =>
        title.value == complete.value
          ? sites.value.filter((site: any) =>
              authorisedSites.value.includes(site.id as never)
            )
          : sites.value
      ),
      sites,
      authorisedSites,
      title,
      content,
      success,
      loading,
      error,
      retry,
      authorisationError,
      complete,
      encryptedRefreshToken,
    };
  },
});
